/* daypart css */
.layout-column {
    flex-direction: column;
}
.layout, .layout-column, .layout-row {
    box-sizing: border-box;
    display: flex;
}
.layout-align-start-center, .layout-align-center-center, .layout-align-end-center, .layout-align-space-between-center, .layout-align-space-around-center {
    margin-left: 3rem;
    align-content: center;
    max-width: 100%;
}

.layout-align-start, .layout-align-start-start, .layout-align-start-center, .layout-align-start-end, .layout-align-start-stretch {
    justify-content: flex-start;
}
.layout-column>.flex {
    min-height: 0;
}
.flex {
    flex: 1;
    box-sizing: border-box;
}
.dl-time-schedule .day-row {
    height: 16px;
    margin-top: 2px;
}
.dl-time-schedule .day {
    cursor: pointer;
    height: 16px;
    font-size: 12px;
    width: 30px;
    overflow: hidden;
    display: inline-block;
    font-weight: bold;
    color: #333;
}
.dl-time-schedule .cell {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin: 1px;
    display: inline-block;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    color: #333;
    background-color: #ccc;
    text-align: center;
}
.dl-time-schedule .cell:hover {
    background-color: #164e7e;
}
.dl-time-schedule .cell.active {
    background: #2170B5;
}
.dl-time-schedule .cell.active:hover {
    background: #164e7e;
}
.hour-column {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin: 1px;
    display: inline-block;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    color: #333;
    text-align: center;
}
.dl-time-schedule .clear-all {
    float: right;
    font-weight: bold;
    margin-top: 10px;
    margin-right: 3em;
}
.dl-time-schedule .clear-all a.enabled {
    color: #9e9e9e;
}
.dl-time-schedule .clear-all a.enabled:hover {
    color: #164e7e;
}
.dl-time-schedule .clear-all a {
    font-size: 14px;
    font-weight: 600;
    color: #e0e0e0;
    text-decoration: none;
}