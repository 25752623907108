* {
    font-family: 'Poppins', sans-serif !important;
}

html .content {
    position: unset;
}

html::-webkit-scrollbar {
    width: 5px !important;
}

html::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(62, 59, 59, 0.3);
}

.dashboard-calender-button {
    background-color: #ffffff;
    padding: 0.686rem 0.7rem !important;
    border: 1px solid #ededed !important;
    font-size: 12px;
}

.dashboard-datepicker {
    background-color: #ffffff !important;
    height: 2.474rem !important;
    border: 1px solid #82868b !important
}

.custom-wizard {
    display: inline-block !important;
}

.custom-wizard-bs-stepper-box-n {
    margin-right: 4rem !important;
}

.form-control {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ededed;
    border-radius: 0.357rem;
}

.form-control:focus {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #2170B5 !important;
    border-radius: 0.357rem;
}

.input-group-text {
    padding: 0.438rem 1rem;
    margin-bottom: 0;
    text-align: center;
    background-color: white;
    border: 1px solid #ededed;
    border-radius: 0.357rem;
}

.card-text {
    font-size: 13px;
    color: var(--color-dark-grey-2);
}

.dstepper-block {
    /*counter-reset: section;*/
    counter-reset: section 0 page;
}

.form_sub_title::before {
    counter-increment: section;
    content: counter(section);
    width: 35px;
    height: 35px;
    line-height: 28px;
    background-color: #1669B2;
    color: #FFF;
    display: inline-block;
    text-align: center;
    border: 4px solid #FFF;
    letter-spacing: 0.1px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 50px;
}

.form_sub_title {
    margin-bottom: 30px;
}

.Channel_box label {
    position: relative;
    padding: 30px;
    display: block;
    background-color: transparent;
    border: 1px solid #BABABA;
    border-radius: 30px;
}

.Channel_box {
    position: relative;
}

.Channel_box input[type=radio] {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 99;
}

.Channel_box .channel_icon svg path {
    fill: #BABABA;
}

.Channel_box input[type=radio]:checked+label {
    background-color: transparent;
    border: 1px solid #2170B5;
}

.Channel_box input[type=radio]:checked+label::after {
    padding-left: 10px;
    content: '✔';
    position: absolute;
    left: -6px;
    top: -7px;
    background-color: #2170B5;
    color: #FFF;
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    border-radius: 50px;
}

.Channel_box input[type=checkbox]:checked+label::after {
    padding-left: 10px;
    content: '✔';
    position: absolute;
    left: -6px;
    top: -7px;
    background-color: #2170B5;
    color: #FFF;
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    border-radius: 50px;
}

.float-md-left.d-block.d-md-inline-block.mt-25 a {
    font-weight: 600;
    color: #2170B5 !important;
}

.Channel_box input[type=radio]:checked+label .channel_icon svg path {
    fill: #2170B5;
}

.Channel_box label h5 {
    margin-bottom: 10px;
    color: #2170B5;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0px;
    line-height: 26px;
    text-align: center;
}

.Channel_box label p {
    font-size: 16px;
    font-weight: 500;
    color: #BABABA;
    line-height: 25px;
    margin-bottom: 0;
    text-align: center;
}

.ms-3 {
    margin-left: 1rem !important;
}

::after,
::before {
    box-sizing: border-box;
}

div.ma-custom-switch label {
    font-size: 1rem !important;
}

.autocomplete-container {
    width: 85% !important;
}

.progress {
    width: 100% !important;
}

.semi-dark-layout .text-primary {
    color: #0358a1 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.semi-dark-layout .text-primary-sm {
    color: #0358a1 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}

.semi-dark-layout .text-primary-sm:hover {
    font-weight: 500 !important;
}

.rs-btn-sm {
    color: #2170B5;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-btn-primary {
    background-color: #2170B5 !important;
}

/* device type style properties */
.device_type .device_type_col {
    position: relative;
    padding-left: 0;
}

.device_type_col {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}

.form-check-input-ma {
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.30em;
    vertical-align: top;
    border: 1px solid rgba(0, 0, 0, .25);
}

.device_type .device_type_col .form-check-input-ma {
    position: absolute;
    top: 10px;
    z-index: 2;
    left: 30px;
}

.device_type_coldevice_type_col .device_type_label {
    color: #0358a1;
}

.device_type .device_type_col .device_type_label {
    padding: 10px 10px 10px 8px;
    background-color: #F2F2F2;
    border: 1px solid #D2D9F2;
    border-radius: 6px;
    border-bottom-width: 2px;
    position: relative;
    padding-left: 35px;
    z-index: 1;
}

.device_type_label {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

.adformat_label {
    font-size: 10px;
    font-weight: 600;
    text-transform: capitalize;
}

.adformat_label {
    padding: 15px 10px 10px 8px;
    background-color: #F2F2F2;
    border: 1px solid #D2D9F2;
    border-radius: 6px;
    border-bottom-width: 2px;
    position: relative;
    padding-left: 35px;
    z-index: 1;
}

.creative_fs_div {
    position: relative;
    width: 69px;
    height: 60px;
    overflow: hidden;
    display: flex;
}

.creative_fs_div img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: flex;
}

.device_type .device_type_col .device_type_label {
    color: #0358a1;
}

.device_type .device_type_col .form-check-input-ma:checked[type=checkbox]~.device_type_label {
    border-bottom: 2px solid #0358a1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-radio-input-large {
    width: 1.3em;
    height: 1.3em;
    margin-top: 0.10em !important;
    vertical-align: top;
    border: 1px solid rgba(0, 0, 0, .25);
}

.custom-radio-input-medium {
    width: 0.9em;
    height: 0.9em;
    margin-top: 0.2em !important;
    vertical-align: top;
    border: 1px solid rgba(0, 0, 0, .25);
}

.form-info-title {
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #171725;
    text-align: left;
    letter-spacing: 0.09px;
    line-height: 23px;
    vertical-align: middle;
}

.file-drop-area {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 450px; */
    max-width: 100%;
    padding: 50px 30px;
    background-color: #F6F6F7;
    transition: .2s;
}

.choose-file-button {
    padding: 8px 15px;
    font-size: 12px;
    text-transform: uppercase;
}

.file-message {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 1.4;
    color: #A3ABB9;
}

.file-message span {
    color: #3991DE;
    text-decoration: underline;
}

.ma-file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.upload-file-details {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none 0.5s ease-in-out;
    /* transition-property: visibility, opacity; */
}

.uploaded-file_info {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    top: 10px;
}

.uploaded-file_info::before,
.uploaded-file_info::after {
    content: '';
    position: absolute;
    bottom: 30px;
    width: 0;
    height: 6px;
    background-color: #F2F2F2;
    border-radius: 50px;
}

.uploaded-file_info::before {
    width: 100%;
}

.uploaded-file_info::after {
    width: 50%;
    background-color: #3991DE;
}

.uploaded-file_count,
.upload_file_counter {
    font-size: 16px;
    font-weight: 500;
    color: #5A607F;
}

.upload_file_counter {
    color: #3991DE;
}

.creative_upload_p {
    color: #5A607F;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.creativeTypeModal .modal-header .modal-title {
    color: #2A69B2;
    margin-bottom: 5px;
}

.modal-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 0;
    text-transform: capitalize;
    padding: 15px 0 0;
}

.creativeTypeModal .modal-header p,
.creativeType_box p {
    color: #5A607F;
    font-size: 15px;
    font-weight: 500;
}

.creativeType_box p {
    font-size: 12px;
}

.CreativeTypeBtn {
    display: inline-block;
    text-align: center;
    letter-spacing: 1.25px;
    color: #FFF;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    background-color: #1669B2;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 0.3s ease;

}

.CreativeTypeBtn a {
    color: #FFF;
}

.bg-search-bar {
    background-color: #f4f5f5;
}

.creative_selected_tiles {
    height: 65px;
    width: 70px;
    background-color: #ebedf4;
    border: 1px solid #D2D9F2;
    border-radius: 0.2rem !important;
}

.creative_selected_tiles img {
    border-radius: 0.2rem !important;
}

.end_card_tiles {
    height: 9.0rem;
    width: 8.0rem;
    background-color: #ebedf4;
    border: 1px solid #D2D9F2;
    border-radius: 0.2rem !important;
}

.creative_prev_uploaded_header,
.creative_prev_uploaded_footer {
    background-color: rgb(36, 35, 35);
    height: 30px;
}

.creative_prev_uploaded_header input {
    margin-left: 0rem !important;
    margin-top: 0.7rem !important;
}

.creative_prev_uploaded_header .more {
    text-align: end;
    padding: 0.3rem 0 0 0;
    color: white;
}

.creative_body {
    height: 260px !important;
    width: 172px !important;
}

.creative_end_card_body {
    box-shadow: 0 4px 4px #b6b5b5;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    background-color: rgb(41, 40, 40);
    margin-bottom: 20px !important;
    display: block;
}

.creative_end_card_body img {
    width: 100%;
    height: 100%;
}

.creative_prev_uploaded_body {
    align-items: center;
    display: flex;
    justify-content: center;
    background: #efefef;
    display: block;
    height: 120px !important;
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.creative_prev_uploaded_body span {
    display: contents;
}

.creative_prev_uploaded_body.p-0.col {
    height: 120px !important;
}

.creative_prev_uploaded_body img {
    max-height: 120px;
    max-width: 100%;
    height: auto;
    width: auto;
}

.creative_edit_main {
    width: 140px;
    height: 130px;
}

.creative_edit_main img {
    width: 100%;
    height: 100%;
}

.creative_edit_endcard img {
    width: 100%;
    height: 100%;
    padding: 10px 0px;
    background-color: rgb(36, 35, 35);
}

.creative_review {
    background-color: #f5f5f5;
    border-radius: 6px;
    box-shadow: 0 4px 4px #00000040;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 360px;
    padding: 16px;
    width: 100%;
}

.campaign_review {
    background-color: #f5f5f5;
    border-radius: 6px;
    box-shadow: 0 4px 4px #00000040;
}

.custom_border_bottom_input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #b1b2b5;
    border-radius: 0;
}

.ad-format-spacing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
}

.stepper-divider {
    border: 1px solid #4e73df;
}

.targeting-info,
.inventory-info,
.creatives-info {
    margin-left: -2rem !important;
}

.select-video-files,
.select-html-files,
.select-image-files {
    margin-left: -4rem !important;
}

.add-an-end-card {
    margin-left: -4rem !important;
}

.edit-details {
    margin-left: -2.5rem !important;
}

.tracker-and-schedule,
.review-publish {
    margin-left: -5rem !important;
}

.review-and-save {
    margin-left: -6rem !important;
}

.general-info {
    margin-left: -35px !important;
}

.budget-info {
    margin-left: -1.5rem !important;
}

.personal-info {
    margin-left: -1.5rem !important;
}

button.border-0 {
    border: 0 !important;
}

.btn-group button.font-family-roboto {
    white-space: nowrap !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.01em !important;
}

#madropdown.delete-dropdown-menu {
    padding: 0.25rem !important;
    min-width: 9em !important;
}

#madropdown .dropdown-item {
    padding: 0.35rem 1.28rem !important;
}

.selected-close {
    align-items: center;
    background: rgba(255, 255, 255, .9);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 18px;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 18px;
}

.semi-dark-layout .ma-button {
    font-weight: 600 !important;
    letter-spacing: 0.05em !important;
    padding: 1.2rem !important;
}

.semi-dark-layout .ma-stepper-button {
    font-weight: 600 !important;
    letter-spacing: 0.05em !important;
    padding: 1.0rem !important;
}

.semi-dark-layout .ma-button-small {
    font-weight: 600 !important;
    letter-spacing: 0.05em !important;
}

.semi-dark-layout .ma-button-custom {
    font-weight: 500 !important;
    letter-spacing: 0.01em !important;
    padding-left: 0.75em;
    padding-right: 1.0em;
}

.semi-dark-layout .ma-dropdown-menu {
    min-width: 18em !important;
}

.semi-dark-layout .ma-dropdown-menu-scroll {
    min-width: 18em !important;
    overflow-y: scroll !important;
}

.camplist-search {
    height: 3.0rem !important;
}

.ma-custom-checkbox,
.ma-custom-radio {
    height: 1.2em !important;
    width: 1.2em !important;
}

.semi-dark-layout .ma-dropdown-menu-sm {
    min-width: 10em !important;
}

.semi-dark-layout .ma-dropdown-menu-sm2 {
    min-width: 27.2em !important
}

.ma-math .btn {
    width: 100px;
    padding: 0.2rem 1rem !important;
    border-radius: 2.358rem !important;
    background-color: #c0c0ca !important;
    color: black !important;
    margin-left: 4rem;
}

.css-1n6sfyn-MenuList {
    max-height: 190px !important;
    z-index: 999 !important;
}

.select__menu {
    z-index: 4 !important;
}

li.timeline-item>span.timeline-point-primary {
    z-index: 0 !important;
}

.wbl-width {
    width: 380px !important;
}

div.wbl-width>button {
    padding: 0rem !important;
    border-radius: 0rem !important;
    border: 0px !important;
    box-shadow: none !important;
}

div.wbl-width>button:hover {
    box-shadow: none !important;
}

div.autocomplete-container {
    width: 100% !important;
}

.new-app-list {
    color: #1669B2 !important;
    font-size: medium;
    font-weight: 600;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #0358a1 !important;
    background-color: #0358a1 !important;
    color: #373342 !important;
}

.custom-control-input:active~.custom-control-label::after {
    background-color: #0358a1 !important;
    border-color: #0358a1 !important;
}

.semi-dark-layout .custom-filter.dropdown-menu {
    margin-top: 15px;
    overflow: hidden;
    min-width: 37em !important;
    max-height: unset !important;
}

.semi-dark-layout .dropdown-menu {
    margin-top: 15px;
    overflow: hidden;
    min-width: 0em !important
}

.semi-dark-layout .useraccount.dropdown-menu {
    margin-top: 0px;
}

.semi-dark-layout .creative-edit-column.dropdown-menu {
    margin-top: 10px;
    overflow: hidden;
    min-width: 29em !important;
    left: -35px !important;
}

.semi-dark-layout .ma-math .dropdown-menu {
    margin-top: 0px !important;
}

.form-check-input[type='radio']:checked {
    accent-color: #556FF6;
}

.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
    border-color: rgb(5, 5, 6, 0.30) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: darkgray !important;
}

.ma-fileuploader-container .uppy-DragDrop-label {
    margin-top: 0.5rem;
    margin-bottom: 1.0rem;
}

.ma-fileuploader-container .uppy-Root .uppy-DragDrop-browse {
    color: var(--color-light-grey-1) !important;
    padding: 0.5rem 2rem;
    margin: 0 0.5rem;
    border: 1px solid;
    background-color: var(--color-dark-blue-5);
    font-weight: 500;
}

.ma-fileuploader-container .uppy-DragDrop-inner>svg {
    /* margin-left: -1rem */
    visibility: hidden;
    height: 0px !important;
}

.autocomplete-container.d-flex.has-close ul.suggestions-list {
    margin-top: 3.5rem !important;
}

.autocomplete-container.d-flex.has-close input:valid ul.suggestions-list {
    margin-top: 10.0rem !important;
}

.btn-primary {
    border-color: #0358a1 !important;
    background-color: #0358a1 !important;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background-color: #0358a1 !important;
}

.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #0358a1;
}

.page-item.active .page-link {
    background-color: #0358a1;
}

input[type="checkbox"]:focus {
    outline: 0;
}

.custom-checkbox .custom-control-input:focus .custom-control-label::before {
    border-color: none;
    box-shadow: #0358a1 !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #0358a1 !important;
}

.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-circle {
    background-color: #0358a1 !important;
    box-shadow: 0 3px 6px 0 rgba(115, 103, 240, 0.4);
}

.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
    color: #0358a1 !important;
}

.btn-secondary {
    border-color: #0358a1 !important;
    background-color: #0358a1 !important;
}

.helpcircle {
    color: darkslategray;
}

.form-control {
    border-color: #a9a9a9;
    color: #373342;
}

.select__placeholder,
.css-14el2xx-placeholder,
.form-control::placeholder {
    color: #c2b7b7 !important
}

.flatpickr-input {
    color: #17172596;
}

.btn-outline-primary {
    border: 1px solid #0358a1 !important;
    background-color: transparent;
}

.btn-outline-primary {
    color: #0358a1;
}

[dir] .input-group-text {
    border: 1px solid #b3adad;
}

.css-k3ockt-control:hover,
.css-k3ockt-control {
    border-color: #817c7c94;
}

.uppy-Root .uppy-DragDrop-browse {
    color: #0358a1 !important;
}

.uppy-Root .uppy-DragDrop-arrow {
    fill: #0358a1 !important;
}

.form-check-input[type='radio']:checked {
    accent-color: #0358a1;
}

input[type='radio'],
input[type='checkbox'] {
    accent-color: #0358a1;
}

.border-primary {
    border: 1px solid #0358a1 !important;
}

.timeline .timeline-point-primary.timeline-point-indicator {
    background-color: #0358a1 !important;
}

label {
    color: #373342d1;
}

.text-secondary {
    color: #373342de !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.text-secondary-normal {
    color: #373342 !important;
    font-weight: 400 !important;
    font-size: 15px !important;
}

.text-secondary-sm {
    color: #373342 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.autocomplete-search,
.autocomplete-search:focus {
    display: inline-block;
    padding: 10px 10px;
    line-height: 100%;
}

.ma-checkbox-md {
    height: 1.2rem;
    width: 1.2rem;
}

a {
    color: #0358a1
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label,
.custom-switch .custom-control-label {
    font-size: 15px !important
}

.custom-switch .custom-control-input:active~.custom-control-label::before {
    background-color: #0358a1;
}

.css-1rv6cxx-control {
    border-color: rgb(0 0 0 / 29%) !important;
}

.custom-filter-select {
    color: #82868b !important;
}

.semi-dark-layout .custom-filter-apply,
.custom-filter-select:hover {
    box-shadow: none !important;
    color: #0358a1 !important;
}

.semi-dark-layout .custom-filter-apply:hover {
    box-shadow: none !important;
    background-color: #f8f9fa !important;
}

.timeline .timeline-item.dropdown {
    padding-bottom: 0.5rem !important;
}

.semi-dark-layout .bs-stepper .bs-stepper-header .line {
    margin-left: 0px;
    border: 1.5px solid rgba(12, 19, 20, 0.167) !important;
}

.ma-cursor-not-allowed {
    cursor: not-allowed !important;
}

.flatpickr-calendar .flatpickr-day.selected {
    background: #006ef7 !important;
    border-color: #006ef7 !important;
}

.flatpickr-calendar .flatpickr-day.today {
    border-color: #006ef7 !important;
}

.btn-cf {
    text-align: left;
    width: 260px;
}

.progress {
    height: 8px;
}

.progress-bar-25 .progress-bar {
    background-color: #59B994;
}

.progress-bar-50 .progress-bar {
    background-color: #0390bc;
}

.progress-bar-75 .progress-bar {
    background-color: #eb5d5d;
}

.semi-dark-layout .dropdown-menu {
    padding: 10px;
}

.css-47z9mc-multiValue {
    background-color: #0358a1;
}

.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
    color: #0358a1 !important;
    background-color: rgba(115, 103, 240, 0.12);
}

.btnDrop button {
    border-color: #82868b !important;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content {
    font-weight: 500;
}

.semi-dark-layout .rdt_TableCol .rdt_TableCol_Sortable {
    font-weight: 500;
    font-size: 14px;
    color: #29292b;
}

#bottom-border {
    border-bottom: 1px solid #60535373 !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
}

.semi-dark-layout .cust-btn-outline-secondary {
    border: 1px solid #60535373 !important;
    height: 2.7rem;
    border-radius: 5px !important;
}

.divide {
    border: 1px solid #5e545440;
    border-radius: 10px;
    padding: 10px 15px;
}

.dividerLine {
    border-right: 1px solid #d3cccc40;
}

.btn-group>.btn:not(:first-child),
[dir="ltr"] .btn-group>.btn-group:not(:first-child) {
    margin-left: 1px !important;
}

.selectall {
    padding-left: 1.8rem !important;
}

.floating-text {
    position: absolute;
    top: -8px;
    padding: 0 4px;
    background: #ffffff;
    font-size: 10px;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #0358a1;
    background-color: #0358a1;
}

.dropdownmenu-creatives {
    left: -50px !important;
}

.select-campaign-filter {
    right: 30px;
    position: absolute;
    bottom: 11px;
    z-index: 4;
    color: #8d899ecc;
}

@media screen and (min-width: 1536px) {
    .camp-filter {
        padding-top: 0rem;
    }
}

@media screen and (max-width: 1436px) {
    .camp-filter {
        display: flex;
        padding-top: 1rem;
    }
}

.center-search-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.campaign-AutoComplete,
.creative-AutoComplete {
    height: 38px !important;
}

.search-campaign {
    height: 42px;
}

.rs-picker-toggle-wrapper {
    display: flex !important;
}

#crFileDropContainer {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}

.ma-row-expand {
    width: 680px;
    word-wrap: break-word !important;
    display: inline-block !important;
    font-size: 0.9rem !important;
    font-weight: 400;
}

.position-top-200 {
    top: -200px !important;
}

.custom-file-label::after {
    border-bottom: inherit;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #0358a1;
}

.ashtrig-red {
    color: #ff0000b8;
}

.edit-button {
    background-color: unset !important;
    border: unset;
    border-top-color: unset;
    border-right-color: unset;
    border-bottom-color: unset;
    border-left-color: unset;
    margin: 0px;
    padding: 0px;
}

.btn-success {
    background-color: rgb(3, 141, 3) !important;
}

#statusBannerBar,
#statusRmaBar {
    padding-top: 10px !important;
}

.add-more {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #9e9e9e !important;

}

.new-brand-list {
    color: #1669B2 !important;
    font-size: medium;
    font-weight: 600;
    background-color: #f1f1f1;
}

.review-save-landscape {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%
}

.review-save-portrait {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%
}

.landscapeMobileImage {
    height: 160px;
    width: 350px;
    position: absolute;
}

.landscapeImage {
    position: absolute;
    width: 336px;
    height: 145px;
    display: grid;
    background-color: #1669b280;
    place-content: center;
    border-radius: 12px;
}

.portraitMobileImage {
    height: 270px;
    width: 140px;
    position: absolute;
}

.portraitImage {
    position: absolute;
    height: 258px;
    width: 126px;
    display: grid;
    place-content: center;
    background-color: #1669b280;
    border-radius: 11px;
}

.modal-dialog-centered .modal-content {
    width: 800px !important
}

input[type=range] {
    accent-color: #2170B5 !important;
}

.html_FS_img span {
    width: 100%;
    height: 100%;
    display: inline;
}

.html_FS_img img {
    max-width: 100%;
    max-height: 100%;
}

.html-edit-Img img {
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
}

.html-edit-Img {
    height: 100%;
    justify-content: center;
    display: flex;
}

.autocompete-trash:hover {
    padding: 2px;
    border: 1px solid #c2bdf6;
    border-radius: 8px;
    background: rgba(115, 103, 240, 0.12) !important;
    color: #7367f0 !important;
}

svg.autocompete-trash:hover {
    height: 21px;
    width: 21px;
}

.custom-filter-container {
    overflow: scroll;
    height: 120px;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
    width: auto;
}

.applyBtn {
    background-color: var(--color-dark-blue-5) !important;
    color: azure;
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-radius: 5px;
}

.applyBtn.dropdown-item:hover {
    color: azure !important;
}

.applyBtn.dropdown-item:active {
    color: azure;
    background-color: #0358a1 !important;
}

.Video_Input {
    display: flex;
    justify-content: center;
    max-width: 100%;
    max-height: 140px;
}

.VideoInput_video {
    max-width: 100% !important;
}

.ma-thumbnail {
    width: 68px;
    height: 63px;
}

.creative_edit_div {
    position: relative;
    width: 250px;
    height: 300px;
    background-color: #efefef;
    overflow: hidden;
}

.creative_edit_div img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.cancel-btn:hover {
    border-radius: 50%;
    background-color: #c4c4e2;
}

.index-change {
    z-index: -2 !important;
}

.index-change~.app-content {
    z-index: -3 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #0358a1 !important;
}

h5 {
    color: #5e5873 !important;
}

.noUi-handle {
    border-radius: 0 !important;
}

.noUi-horizontal .noUi-handle {
    height: 1.5rem !important;
}

.nav-pills .nav-link.active {
    border-color: #0358a1 !important;
    box-shadow: 0 4px 18px -4px rgba(184, 179, 242, 0.65);
}

.custom-control {
    z-index: auto;
}

.position-bottom-20 {
    bottom: 20px;
}

.applist-dropdown {
    background-color: rgb(194, 192, 251) !important;
    border-color: rgb(194, 192, 251) !important;
    border-left: unset !important;
    border-right: unset !important;
}

.applist-dropdown:active,
.applist-dropdown:focus {
    background-color: rgb(194, 192, 251) !important;
    border-color: rgb(194, 192, 251) !important;
}

.w-100.text-center.text-secondary-normal.font-small-3.list-group-item:hover {
    cursor: pointer;
    background-color: #cccff2;
}

.w-100.text-center.list-group-item:hover {
    color: #0358a1 !important;
}

.height-100 {
    height: 100% !important;
}

.chip {
    background: #0358a1 !important;
    border-radius: 3px !important;
}

.highlightOption {
    background: #2c78bb !important;
    color: #fff;
}

.multiSelectContainer li:hover {
    background: #0358a1b5 !important;
    color: #fff;
    cursor: pointer;
}

.highlightOption .checkbox {
    margin-right: 8px;
}

.option .checkbox {
    margin-right: 8px;
}

.custom-filter-txt {
    color: #c2b7b7 !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
}

.custom-filter-txt.btn {
    padding: 0.6rem 0.75rem !important;
}

.onScroll-filter {
    position: fixed;
    z-index: 1;
    top: 26px;
    width: 90%;
    margin-left: -8px;
    left: 262px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 13px;
    padding-right: 13px;
    transition: background-color 0.3s ease-in-out;
}

.cumulative-outline {
    width: 100%;
    height: 100%;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
}

.semi-dark-layout .rdt_TableCol .rdt_TableCol_Sortable {
    font-size: 10px !important;
}

.rdt_TableCell {
    font-size: 10px !important;
    padding: 0 !important;
}

.progress-font {
    font-size: 10px;
}

.bs-stepper .step-trigger.disabled,
.bs-stepper .step-trigger:disabled {
    opacity: 1 !important;
}

.landscapeImage .html-edit-Img img {
    max-height: 141px !important;
}

.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .accounts-dropdown-menu {
    top: 25px !important;
}

.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .transaction-dropdown-menu {
    top: 30px !important;
}

.searchWrapper {
    max-height: 130px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.optionListContainer {
    margin-top: 6px;
    box-shadow: 1px 1px 4px 4px #f3eeee;
}

.btn-link {
    color: #0358a1 !important;
    border-color: unset !important;
    background-color: unset !important;
    margin: unset !important;
    padding: unset !important;
    border: unset !important;
    padding-bottom: 4px !important;
    font-weight: 400 !important;
}

.btn-link:focus {
    background-color: unset !important;
}

.btn-link:hover {
    color: #5e50ee !important;
    box-shadow: unset !important;
    background-color: unset !important;
}

.tc-modal {
    max-width: 100% !important;
    margin: 30px !important
}

.tc-modal .modal-content {
    width: 100% !important;
}

.text-primary.breadcrumb-item {
    cursor: default;
}

.active.breadcrumb-item {
    cursor: default;
}

h5 {
    cursor: default;
}

svg.xCircle:hover {
    background-color: darkgray;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
}

.table-Input {
    min-width: 140px;
}

.action-dropdown {
    background-color: unset !important;
    color: #000000a3 !important;
    border: unset !important;
    box-shadow: unset !important;
}

.action-dropdown:active,
.action-dropdown:focus {
    background-color: unset !important;
}

.p-datatable {
    font-size: 10px !important;
    position: unset !important;
}

.p-datatable-thead {
    position: sticky;
    top: -1px;
    z-index: 2;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-left: 0.2rem !important;
    width: 9px;
    height: 9px;
}

.p-datatable>.p-datatable-wrapper {
    overflow: auto;
    float: left;
    width: 100%;
    overflow-y: scroll;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #F5F5F5;
}

.p-datatable-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(62, 59, 59, 0.3);
    background-color: #918a8a;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded {
    width: 225px !important;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
    width: 50px !important;
}

.main-menu {
    top: 0
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header {
    margin-left: 1.2rem !important;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.floating-nav {
    width: 400px !important;
}

.header-navbar.floating-nav {
    position: absolute;
    right: -1.2rem !important;
    top: -0.5rem;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .app-content,
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
    margin-left: 50px !important;
}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 27% !important;
}

.p-button {
    background: none !important;
    border: 1px solid #1669B2 !important;
    color: #556FF6 !important;
    padding: unset !important;
    border-radius: 6px;
}

.p-speeddial-action {
    background: #1669B2 !important;
}

.p-speeddial-list {
    position: absolute;
    left: 26px;
    top: -7px;
}

.content.app-content {
    padding: 10px !important;
}

.p-datatable .p-datatable-tbody>tr {
    color: #080c10 !important;
}

.dropdown-overflow .p-datatable-wrapper {
    overflow: inherit !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1.25rem;
    border: unset !important;
    background: #f8f9fa;
    border-radius: 6px;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: unset !important;
}

.p-accordion .p-accordion-content {
    border: unset !important;
    padding: unset !important;
    padding-top: 10px !important;
}

.multiSelectContainer.is-invalid .searchWrapper {
    border-color: #ea5455;
}

.comparisonTable .p-datatable-thead {
    position: unset;
}

.p-datatable .p-datatable-loading-overlay {
    background-color: rgba(121, 128, 148, 0.38) !important;
    border-radius: 6px;
    z-index: 5 !important;
}

.primereact_autocomplete .p-component {
    width: 100% !important;
    border: 1px solid #b9b6b6;
    height: 39px;
}

.p-component::placeholder {
    font-weight: 300;
    color: rgba(80, 72, 72, 0.484);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #1669b2 !important;
}

.card-header {
    padding: 0.5rem 1.5rem !important;
}

button#dropdown-basic:active {
    background-color: #0358a1 !important;
}

button#dropdown-basic:focus {
    background-color: #0358a1 !important;
}

.spinner-loading {
    --d: 24.6px;
    width: 4.5px;
    height: 4.5px;
    border-radius: 50%;
    color: #6b7394;
    box-shadow: calc(1*var(--d)) calc(0*var(--d)) 0 0,
        calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1.1px,
        calc(0*var(--d)) calc(1*var(--d)) 0 2.2px,
        calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3.4px,
        calc(-1*var(--d)) calc(0*var(--d)) 0 4.5px,
        calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5.6px,
        calc(0*var(--d)) calc(-1*var(--d)) 0 6.7px;
    animation: spinner-a90wxe 1s infinite steps(8);
}

.audienceFlag-input {
    height: 50px;
    border-radius: 40px;
    background-color: rgb(220 213 213);
    display: flex;
    justify-content: center;
}

.p-timeline-event {
    min-height: unset !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.p-timeline-event-opposite {
    display: none;
}

.p-timeline-event-separator {
    align-items: center;
    justify-content: center;
    min-width: 28px !important;
}

.campaign-dropdown .dropdown-menu {
    transform: translate(-10%, -50%) !important;
}

.p-inputtext {
    border: 1px solid #a9a9a9 !important;
    border-radius: 5px !important;
}

.react-select .select__control {
    border-radius: 5px !important;
    border: 1px solid #a9a9a9 !important;
}

.select__indicator svg {
    height: 14px !important;
    width: 14px !important;
    color: #a9a9a9 !important;
    margin-right: 6px !important;
}

@keyframes spinner-a90wxe {
    100% {
        transform: rotate(1turn);
    }
}

.not-disabled {
    pointer-events: all !important;
}

.is-invalid .select__control {
    border: 1px solid #ea322e !important;
    border-radius: 5px !important;
}

.is-invalid .p-inputtext {
    border: 1px solid #ea322e !important;
    border-radius: 5px !important;
}

.p-slider .p-slider-range {
    background: #1669B2 !important;
}

.p-message.p-message-info {
    background: #e9e9ff;
    border-left: 5px solid #0358A1 !important;
    color: #0358A1 !important;
}

.custom-modal .modal-dialog {
    /* margin: auto; */
    max-width: 1200px;
    overflow: auto;
}

.custom-modal .modal-body {
    overflow: auto;
}

.audience-modal .modal-dialog {
    max-width: 700px;
}

.audience-modal .modal-content {
    width: unset !important;
}

.audience-modal button:focus {
    outline: none;
}

.audience-modal .nav-link.active {
    color: #0358A1;
}

.audience-modal .nav-link:after {
    background: linear-gradient(30deg, #0358A1, rgba(80, 73, 159, 0.5)) !important;
}

.copy-button:hover {
    background-color: darkgray;
    cursor: pointer;
}

.fa-interstitial-icon {
    color: #367ac9;
    stroke-width: 0px;
    height: 23%;
    width: 23%;
}

.fa-non-interstitial-icon {
    color: #4785cb;
    height: 23%;
    width: 23%;
    border-radius: 100px;
}

.adFormat_type .Channel_box label {
    padding: 20px;
}

.footer {
    min-height: unset !important;
    padding: 0 !important;
    background-color: rgb(239 239 239 / 50%);
}

.footer>div {
    display: flex !important;
    justify-content: space-between !important;
}

.p-paginator {
    height: 60px !important;
}

.accordion-header>button {
    text-align: center;
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 1.25px;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-500) !important;
    border-radius: 5px !important;
    padding: 0.5rem !important;
    background: transparent;
    border: 1px solid #60535373 !important;
    opacity: 0.5 !important;
    outline: 0px !important;
}

.text-underline {
    text-decoration: underline !important;
}

.page-header {
    font-size: 20px;
}

.page-header-icon {
    width: 22px;
    height: 22px;
}

.react-select .css-1p3m7a8-multiValue,
.react-select .css-v7duua:hover {
    background-color: #1669B2;
    color: #FFF;
}

.react-select .css-9jq23d {
    color: #FFF;
}

.css-1u9des2-indicatorSeparator {
    background-color: transparent;
}

.custom-interval .rs-input-group,
.custom-interval .rs-input-group:active,
.custom-interval .rs-input-group:hover,
.custom-interval .rs-input-group:focus{
    border-color: #b9b6b6 !important;
    box-shadow: none !important;
}

.custom-interval .rs-picker-daterange-header{
    text-align: center;
}

.rs-btn.rs-btn-primary{
    color: #FFF;
}

@media screen and (max-width: 1200px) {
    .header-navbar.floating-nav {
        width: calc(100vw - (100vw - 100%) - calc(2rem - 0.5%)) !important;
        margin-left: 2rem;
    }

    .content.app-content {
        padding-top: 10px !important;
    }

    .page-header {
        font-size: 18px;
    }

    .page-header-icon {
        width: 20px;
        height: 20px;
    }

    .responsive-text {
        font-size: 14px;
    }

    .responsive-icon {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 768px) {
    .semi-dark-layout .bs-stepper .bs-stepper-header {
        display: none;
    }

    .semi-dark-layout .custom-filter.dropdown-menu {
        min-width: unset !important;
        left: 0 !important;
        max-height: unset !important;
        overflow: scroll !important;
    }

    .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .accounts-dropdown-menu {
        top: 42px !important;
        max-width: auto !important;
        left: unset !important;
        right: 0 !important;
    }

    .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .transaction-dropdown-menu {
        top: 42px !important;
        max-width: 250px;
        right: 160px !important;
        left: unset !important;
    }

    .page-header {
        font-size: 16px;
    }

    .page-header-icon {
        width: 18px;
        height: 18px;
    }

    .responsive-text {
        font-size: 14px;
    }

    .responsive-icon {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 578px) {
    .semi-dark-layout .creative-edit-column.dropdown-menu {
        left: 0px !important
    }

    .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .accounts-dropdown-menu {
        top: 42px !important;
    }

    .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .transaction-dropdown-menu {
        top: 42px !important;
        max-width: 250px;
        right: 130px !important;
        left: unset !important;
    }

    .header-navbar.floating-nav {
        width: calc(100vw - (100vw - 100%) - calc(2rem)) !important;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .nav-pills {
        flex-direction: column !important;
    }

    .page-header {
        font-size: 14px;
    }

    .page-header-icon {
        width: 16px;
        height: 16px;
    }

    .responsive-text {
        font-size: 12px;
    }

    .responsive-icon {
        width: 14px;
        height: 14px;
    }
}